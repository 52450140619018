import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { IS_IN_DEVELOPMENT } from "./functions-origin";
import { fetchAndActivate, getRemoteConfig } from "firebase/remote-config";
import { RemoteDefaultConfig } from "./services/RemoteConfig";
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

// Your web app's Firebase configuration
const app =
  ((window.FIREBASE_APPCHECK_DEBUG_TOKEN = IS_IN_DEVELOPMENT),
  initializeApp({
    apiKey: "AIzaSyCzOH04aRIM7xAQCtWvxnu6PH3zbXXRC3s",
    authDomain: "cs-nucleus.firebaseapp.com",
    projectId: "cs-nucleus",
    storageBucket: "cs-nucleus.appspot.com",
    messagingSenderId: "542092713814",
    appId: "1:542092713814:web:583469dfadd4c9a55080f7",
    measurementId: "G-G2DMHBDCLY",
  }));

// Initialize App Check
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LcL7gcjAAAAAKnFaelCNK53GyuAP28bBtstSUDF"),
  isTokenAutoRefreshEnabled: true,
});

export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);

// Initialize Remote Config and get a reference to the service
export const remoteConfig = getRemoteConfig(app);
// Determine how long data is cached - in milisecs
remoteConfig.settings.minimumFetchIntervalMillis = 10000;
// Assign the default values
remoteConfig.defaultConfig = RemoteDefaultConfig;
// Get latest from server
fetchAndActivate(remoteConfig);