import axios from "axios";
import { API_URL } from "./functions-origin";

const apiUrl = API_URL;

export async function logIn({ email, password }) {
  const url = `${apiUrl}/login`;
  const res = await axios.post(url, { email, password });
  return res.data;
}

// USERS

export async function getUser({ userIdToken, uid }) {
  const url = `${apiUrl}/user/${uid}`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}

export async function updateUser({
  userIdToken,
  companyId,
  uid,
  name,
  email,
  access,
  autoProjects,
  testrail,
  jira_boards,
  durian_member,
  verified,
}) {
  const url = `${apiUrl}/company/${companyId}/users/${uid}`;
  const res = await axios.put(
    url,
    { email, name, access, autoProjects, testrail, jira_boards, durian_member, verified },
    {
      headers: {
        Authorization: `Bearer ${userIdToken}`,
      },
    }
  );
  return res.data;
}

export async function verifyEmail({ code }) {
  const url = `${apiUrl}/verifyEmail/${code}`;
  const res = await axios.post(url);
  return res.data;
}

export async function resetPassword({ code, password }) {
  const url = `${apiUrl}/verifyEmail/${code}`;
  const res = await axios.post(url, { password });
  return res.data;
}

// Employees

export async function getEmployees({ userIdToken, companyId }) {
  const url = `${apiUrl}/company/${companyId}/employees`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}

export async function createUser({
  userIdToken,
  companyId,
  name,
  email,
  password,
  role,
  sendVerification,
  automationProjects,
}) {
  const url = `${apiUrl}/company/${companyId}/users`;
  const res = await axios.post(
    url,
    { name, email, password, role, sendVerification, automationProjects },
    {
      headers: {
        Authorization: `Bearer ${userIdToken}`,
      },
    }
  );
  return res.data;
}

export async function deleteUser({ userIdToken, companyId, uid }) {
  const url = `${apiUrl}/company/${companyId}/users/${uid}`;
  const res = await axios.delete(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}

// Clients

export async function getClients({ userIdToken, companyId }) {
  const url = `${apiUrl}/company/${companyId}/clients`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}

// AZURE

export async function getAutomationProjects({ userIdToken, companyId }) {
  const url = `${apiUrl}/company/${companyId}/azure/projects`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}

export async function getAutomationProject({ userIdToken, companyId, projectId }) {
  const url = `${apiUrl}/company/${companyId}/azure/projects/${projectId}`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}

export async function getAzureOrganisations({ userIdToken, companyId }) {
  const url = `${apiUrl}/company/${companyId}/azure/organisations`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}

// BUILDS

export async function getAutomationBuilds({ userIdToken, companyId, projectId }) {
  const url = `${apiUrl}/company/${companyId}/azure/${projectId}/builds`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}
export async function getAutomationBuild({ userIdToken, companyId, projectId, buildId }) {
  const url = `${apiUrl}/company/${companyId}/azure/${projectId}/builds/${buildId}`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}
export async function getAutomationBuildLogs({ userIdToken, companyId, projectId, buildId }) {
  const url = `${apiUrl}/company/${companyId}/azure/${projectId}/builds/${buildId}/logs`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}
export async function getAutomationBuildLog({ userIdToken, companyId, projectId, buildId, logId }) {
  const url = `${apiUrl}/company/${companyId}/azure/${projectId}/builds/${buildId}/logs/${logId}`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}

// PIPELINES

export async function getAutomationPipelines({ userIdToken, companyId, projectId }) {
  const url = `${apiUrl}/company/${companyId}/azure/${projectId}/pipelines`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}
export async function getAutomationPipelineRuns({ userIdToken, companyId, projectId, pipelineId }) {
  const url = `${apiUrl}/company/${companyId}/azure/${projectId}/pipelines/${pipelineId}/runs`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}

// SMS
export async function getSMSHistory({ userIdToken, companyId, simId }) {
  const url = `${apiUrl}/company/${companyId}/sms/${simId}`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}

// RELEASES

// Apple
export async function getAppleReleases({ os, showBeta, showRC }) {
  var url = `${apiUrl}/releases/apple/${os}`;

  if (showBeta) {
    url = `${apiUrl}/releases/apple/${os}/beta`;
  }

  if (showRC) {
    url = `${apiUrl}/releases/apple/${os}/rc`;
  }

  const res = await axios.get(url);
  return res.data;
}

// TESTRAIL
// Get all statuses
export async function getTestrailStatuses({ userIdToken, companyId }) {
  const url = `${apiUrl}/company/${companyId}/testrail/statuses`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}
// Get all projects
export async function getTestrailProjects({ userIdToken, companyId }) {
  const url = `${apiUrl}/company/${companyId}/testrail/projects`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}
// Get single project
export async function getTestrailProject({ userIdToken, companyId, projectId }) {
  const url = `${apiUrl}/company/${companyId}/testrail/projects/${projectId}`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}
// Get All runs for a project
export async function getTestrailProjectRuns({ userIdToken, companyId, projectId }) {
  const url = `${apiUrl}/company/${companyId}/testrail/runs/${projectId}`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}
// Get all tests for a run
export async function getTestrailProjectRunTests({ userIdToken, companyId, runId }) {
  const url = `${apiUrl}/company/${companyId}/testrail/tests/${runId}`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}
// Get sesctions for tests
export async function getTestrailProjectRunTestSections({ userIdToken, companyId, projectId, suiteId }) {
  const url = `${apiUrl}/company/${companyId}/testrail/projects/${projectId}/suite/${suiteId}`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}

// Jira Boards
//  All boards
export async function getJiraBoards({ userIdToken, companyId }) {
  const url = `${apiUrl}/company/${companyId}/jira/boards`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}
// Board config
export async function getJiraBoardConfig({ userIdToken, companyId, boardId }) {
  const url = `${apiUrl}/company/${companyId}/jira/boards/${boardId}`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}
// Board issues
export async function getJiraBoardIssues({ userIdToken, companyId, boardId }) {
  const url = `${apiUrl}/company/${companyId}/jira/boards/${boardId}/issues`;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });
  return res.data;
}

export async function postSendEmail({
  userIdToken,
  companyId,
  templateId,
  toEmails,
  holidayStart,
  holidayEnd,
  fromName,
  declineReason,
}) {
  const url = `${apiUrl}/company/${companyId}/send/email/${templateId}`;
  const res = await axios.post(
    url,
    { toEmails, holidayStart, holidayEnd, fromName, declineReason },
    {
      headers: {
        Authorization: `Bearer ${userIdToken}`,
      },
    }
  );
  return res.data;
}
export async function postSendEmailRaw({
  userIdToken,
  templateId,
  toEmails,
  ccEmails,
  holidayStart,
  holidayEnd,
  fromName,
  declineReason,
  fromEmail,
  apiKey,
}) {
  const url = `${apiUrl}/send/email/${templateId}`;
  const res = await axios.post(
    url,
    { toEmails, ccEmails, holidayStart, holidayEnd, fromName, declineReason, fromEmail, apiKey },
    {
      headers: {
        Authorization: `Bearer ${userIdToken}`,
      },
    }
  );
  return res.data;
}
